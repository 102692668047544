<template>
  <codemirror
    ref="editor"
    v-observe-visibility="{
      callback: visibilityChanged,
    }"
    :value="value"
    :options="options"
    @input="input"
  />
</template>
<script>
import { codemirror } from 'vue-codemirror';
import { ObserveVisibility } from 'vue-observe-visibility';

import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/mode/javascript/javascript';

import { debounce } from 'vue-debounce';

export default {
  components: {
    codemirror,
  },
  directives: {
    ObserveVisibility,
  },
  props: {
    options: {
      type: Object,
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    },
    inputEventTimeout: {
      type: Number,
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      tempValue: undefined,
      debouncedInput: undefined,
    };
  },
  async mounted() {
    await import('codemirror/lib/codemirror.css');
    await import('codemirror/theme/base16-dark.css');
    await import('codemirror/theme/nord.css');
  },
  watch: {
    height: {
      handler(h) {
        if (h) {
          this.$nextTick(() => {
            this.$refs.editor.codemirror.setSize(null, h);
          });
        }
      },
      immediate: true,
    },
    inputEventTimeout: {
      handler(v) {
        this.debouncedInput = debounce(() => {
          this.$emit('input', this.tempValue);
        }, v);
      },
      immediate: true,
    },
  },
  methods: {
    input(v) {
      this.tempValue = v;
      if (!this.inputEventTimeout) {
        this.$emit('input', v);
      } else {
        this.debouncedInput();
      }
    },
    visibilityChanged(isVisible) {
      this.$refs.editor.codemirror.refresh();
    },
  },
};
</script>
